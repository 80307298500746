<template>
  <div class="clebex-calendar">
    <header class="calendar-header">
      <div class="calendar-title">
        {{ cal.parsedDateMonth() }} <span>{{ cal.parsedDateYear() }}</span>
      </div>
      <div class="month-switcher icon-app1">
        <button class="month prev" @click="prevMonth()">
          <icon icon="#cx-app1-arrow-left"></icon>
        </button>
        <button class="month current" @click="selectToday">
          <icon icon="#cx-app1-arrow-today"></icon>
        </button>
        <button class="month prev" @click="nextMonth()">
          <icon icon="#cx-app1-arrow-right"></icon>
        </button>
      </div>
    </header>
    <div class="calendar-wrapper" ref="calendarWrapper">
      <template v-if="!cal.expanded">
        <ul class="days-of-the-week-labels">
          <li
            class="day-label"
            v-for="day in cal.labelsOfDaysSingleWeek()"
            v-bind:key="day"
          >
            {{ day }}
          </li>
        </ul>
        <ul class="days-of-the-week">
          <li
            class="day"
            v-for="day in cal.numbersOfDaysToDisplaySingleWeek()"
            :class="{
              today: cal.isToday(day.dateString),
              selected: cal.isSelected(day)
            }"
            :aria-date="day.dateString"
            v-bind:key="day"
          >
            <span class="day-button disabled" v-if="day.outOfRange">
              {{ day.dayNum }}
            </span>
            <button
              v-else
              class="day-button"
              @click="
                selectDateFn(day.dateObj);
                cal.selectDate(day.dateObj);
              "
            >
              {{ day.dayNum }}
            </button>
          </li>
        </ul>
      </template>
      <template v-else>
        <ul class="days-of-the-week-labels">
          <li
            class="day-label"
            v-for="day in cal.labelsOfDaysWholeMonth()"
            v-bind:key="day"
          >
            {{ day }}
          </li>
        </ul>
        <ul class="days-of-the-week">
          <li
            class="day"
            v-for="day in cal.numbersOfDaysToDisplayWholeMonth()"
            :class="[
              {
                today: cal.isToday(day.dateString),
                selected: cal.isSelected(day)
              },
              day.occupancy_percentage
                ? `occupancy ${day.occupancy_percentage}`
                : ''
            ]"
            :aria-date="day.dateString"
            :aria-date-obj="JSON.stringify(day.dateObj)"
            :aria-day-num="day.dayNum"
            v-bind:key="day"
          >
            <span class="day-button disabled" v-if="day.outOfRange">
              {{ day.dayNum }}
            </span>
            <button
              v-else
              class="day-button"
              @click="
                selectDateFn(day.dateObj);
                cal.selectDate(day.dateObj);
              "
            >
              {{ day.dayNum }}
            </button>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
import ClebexCalendar from "@/functionalties/calendar";
import { mapState } from "vuex";
import { DateTime } from "luxon";

export default {
  name: "DynamicCalendar",
  data() {
    return {
      cal: Object.create(ClebexCalendar)
    };
  },
  computed: {
    ...mapState("timezone", ["timezones"]),
    daysInTheWeek() {
      return this.cal && this.cal.numbersOfDaysToDisplaySingleWeek();
    }
  },
  created() {
    if (this.selectedMonth) {
      this.cal.setSelectedMonth(this.selectedMonth);
    }
    if (this.occupiedDates) {
      this.cal.occupiedDates = this.occupiedDates;
    }
    if (this.disablePreviousDays) {
      this.cal.disablePreviousDays = this.disablePreviousDays;
    }
    if (this.selectedDate) {
      this.selectDate(this.selectedDate);
    }
  },
  updated() {
    if (this.occupiedDates) {
      this.cal.occupiedDates = this.occupiedDates;
    }
  },
  watch: {
    timezone: {
      handler() {
        if (this.timezone && this.timezones && this.timezones.data) {
          this.cal.timezone = this.timezone;
          if (this.selectedDate) {
            this.selectDate(this.selectedDate);
          } else {
            this.selectDate(this.cal.today());
          }
        }
      }
    },
    selectedDate(newval, oldval) {
      if (newval !== oldval && !this.cal.selectedDate) {
        this.selectDate(newval);
      }
    }
  },
  methods: {
    prevMonth() {
      this.cal.prevMonth();
      this.selectMonthFn(this.cal.month(), this.cal.year());
    },
    nextMonth() {
      this.cal.nextMonth();
      this.selectMonthFn(this.cal.month(), this.cal.year());
    },
    selectToday() {
      const today = this.cal.today();
      const monthIndex = today.month;
      this.selectDateFn(today);
      this.cal.selectDate(today);
      if (this.cal.month() !== monthIndex) {
        this.cal.setSelectedMonth(monthIndex);
      }
    },
    selectDate(date) {
      let today;
      if (typeof date === "string") {
        today = DateTime.fromISO(date).setZone(this.timezone, {
          keepLocalTime: true
        });
      } else {
        today = date;
      }

      const monthIndex = today.month;
      this.selectDateFn(today);
      this.cal.selectDate(today);
      if (this.cal.month() !== monthIndex) {
        this.cal.setSelectedMonth(monthIndex);
      }
    }
  },
  props: {
    selectedMonth: {
      required: false,
      type: Number
    },
    selectedDate: {
      required: false,
      type: String
    },
    occupiedDates: {
      required: false,
      type: Array
    },
    selectDateFn: {
      required: true,
      type: Function
    },
    selectMonthFn: {
      required: true,
      type: Function
    },
    disablePreviousDays: {
      type: Boolean
    },
    timezone: {
      required: false,
      type: String || null
    }
  }
};
</script>
