<template>
  <section class="space-calendar">
    <dynamic-calendar
      :select-month-fn="selectMonthFn"
      :occupied-dates="occupiedDates"
      :select-date-fn="selectDateFn"
      :disable-previous-days="disablePreviousDays"
      :selectedDate="selectedDate"
      :timezone="timezone"
    ></dynamic-calendar>
  </section>
</template>

<script>
import DynamicCalendar from "@/components/plans/DynamicCalendar";
import { mapState } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import {
  getOffsetFromDateString,
  getTimezoneCodeFromOffset
} from "@/services/helpers";
export default {
  name: "space-calendar",
  components: {
    DynamicCalendar
  },
  props: {
    selectDateFn: {
      required: true,
      type: Function
    },
    selectMonthFn: {
      required: true,
      type: Function
    },
    occupiedDates: {
      type: Array
    },
    disablePreviousDays: {
      type: Boolean
    },
    selectedDate: {
      required: false,
      type: String
    }
  },
  data() {
    return {
      timezone: null
    };
  },
  computed: {
    ...mapState("plan", ["selectedResource", "slots"]),
    ...mapState("timezone", ["timezones"])
  },
  created() {
    if (this.selectedResource) {
      const id = this.selectedResource.id;
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      return httpServiceAuth
        .get(`${apiEndpoints.company.resources}/${id}`, {
          params: {
            includes: ["timezone"]
          }
        })
        .then(response => {
          if (response.data && response.data.data) {
            this.timezone =
              response.data.data &&
              response.data.data.timezone &&
              response.data.data.timezone.code;
          }
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, {
            root: true
          });
        });
    } else if (
      this.slots &&
      this.slots.data &&
      this.timezones &&
      this.timezones.data
    ) {
      const offset = getOffsetFromDateString(this.slots.data[0].slots[0].from);
      this.timezone = getTimezoneCodeFromOffset({
        offset,
        timezones: this.timezones.data
      });
    }
  }
};
</script>
